.home-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.box {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  padding: 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  text-align: center;
  width: 60%;
}